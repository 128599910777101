import React from 'react';
import { Button } from "./components/ui/button";
import { Share2, Download } from 'lucide-react';
import { useNavigate } from 'react-router-dom'; // Updated import
import html2canvas from 'html2canvas';

const SplitResult = ({ billName, totalBill, splitAmounts, isSharedBill, generateShareableLink, receiptUrl }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const shareAsBillImage = async () => {
    const element = document.getElementById('bill-details');
    if (!element) return;

    try {
      const canvas = await html2canvas(element);
      const imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      
      if (navigator.share) {
        await navigator.share({
          files: [new File([imageBlob], 'bill-split.png', { type: 'image/png' })],
          title: 'Bill Split Details',
          text: 'Here are the details of our split bill.'
        });
      } else {
        // Fallback for browsers that don't support native sharing
        const imageUrl = URL.createObjectURL(imageBlob);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'bill-split.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(imageUrl);
      }
    } catch (error) {
      console.error('Error sharing bill image:', error);
      alert('Failed to share the bill image. Please try again.');
    }
  };

  const handleShareLink = () => {
    const link = generateShareableLink();
    navigator.clipboard.writeText(link);
    alert('Shareable link for the bill split copied to clipboard!');
  };

  // Function to navigate to the home page
  const handleNewBill = () => {
    navigate('/'); // Change this to your home route
  };

  return (
    <div className="container">
      <div id="bill-details" className="mt-4 p-4 bg-gray-50 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Split Result:</h3>
        <p className="text-sm mb-2">Bill Name: {billName}</p>
        <p className="text-sm mb-2">Total Bill: ${totalBill}</p>
        {receiptUrl && (
          <p className="text-sm mb-2">
            Receipt: <a href={receiptUrl} target="_blank" rel="noopener noreferrer">View Receipt</a>
          </p>
        )}
        {splitAmounts.map((split, index) => (
          <p key={index} className="text-sm">
            {split.name}: ${split.amountDue} {parseFloat(split.amountDue) < 0 ? '(to be refunded)' : '(to pay)'}
          </p>
        ))}
      </div>
      {!isSharedBill && (
        <div className="flex space-x-2 mt-4">
          <Button onClick={shareAsBillImage} className="flex-1">
            <Download className="h-4 w-4 mr-2" /> Download
          </Button>
          <Button onClick={handleShareLink} className="flex-1">
            <Share2 className="h-4 w-4 mr-2" /> Share
          </Button>
        </div>
      )}
      <Button onClick={handleNewBill} className="mt-4 w-full">
        Click here to split a new bill!
      </Button>
    </div>
  );
};

export default SplitResult;
