import React, { useState } from 'react';
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { X, DollarSign, Upload } from 'lucide-react';
import AWS from 'aws-sdk';

// Configure AWS with R2 details
AWS.config.update({
  accessKeyId: 'a946e09f8ce1887599f5ac5edfbbfd29',
  secretAccessKey: '5c3716f430aec7477a70b9e5b17d419829fad7cc44f71c164426e429980b1ecc',
  endpoint: 'https://51462ddf418aa945734f240f93d5788c.r2.cloudflarestorage.com',
  s3ForcePathStyle: true, // Needed for R2
});

const s3 = new AWS.S3();

const BillForm = ({
  billName,
  setBillName,
  totalBill,
  setTotalBill,
  friends,
  setFriends,
  receipt,
  setReceipt,
  fileInputRef,
  handleSplit,
  setReceiptUrl
}) => {
  const [uploadedUrl, setUploadedUrl] = useState('');

  const handleAddFriend = () => {
    const newId = Math.max(...friends.map(f => f.id), 0) + 1;
    setFriends([...friends, { id: newId, name: `Friend ${newId}`, payment: '0' }]);
  };

  const handleInputFocus = (friendId, field) => {
    console.log(`Friend with ID ${friendId} focused on ${field}`);
  };

  const handleRemoveFriend = (id) => {
    if (friends.length > 1) {
      setFriends(friends.filter(friend => friend.id !== id));
    }
  };

  const handleFriendChange = (id, field, value) => {
    setFriends(friends.map(friend =>
      friend.id === id ? { ...friend, [field]: value } : friend
    ));
  };

  // Handle file upload to R2
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setReceipt(file); // Store file for later use

      const params = {
        Bucket: 'billsplitter',
        Key: `${Date.now()}_${file.name}`, // Unique filename
        Body: file,
        ContentType: file.type,
      };

      try {
        // Upload the file to R2
        const data = await s3.upload(params).promise();
        const receiptId = data.Key; // Store the unique ID
        const receiptUrl = `https://upload.pixaraft.com/${receiptId}`;
        setUploadedUrl(receiptUrl); // Save the uploaded URL
        setReceiptUrl(receiptUrl); // Pass the URL to the parent component
      } catch (error) {
        console.error('Error uploading receipt:', error);
      }
    }
  };

  return (
    <div className="container">
      <div>
        <label htmlFor="billName" className="block text-sm font-medium text-gray-700">Bill Name</label>
        <div className="flex items-center mt-1">
          <Input
            type="text"
            id="billName"
            value={billName}
            onChange={(e) => setBillName(e.target.value)}
            className="flex-grow"
            placeholder="Enter bill name"
          />
          <Button
            type="button"
            onClick={() => fileInputRef.current.click()}
            variant="ghost"
            size="icon"
            className="ml-2"
          >
            <Upload className="h-4 w-4" />
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept="image/*,.pdf"
            className="hidden"
          />
        </div>
        {receipt && <p className="text-sm text-gray-500 mt-1">Receipt: {receipt.name}</p>}
        {uploadedUrl && <p className="text-sm text-green-500 mt-1"><a href={uploadedUrl} target="_blank" rel="noopener noreferrer">Uploaded successfully</a></p>}
      </div>

      <div>
        <label htmlFor="totalBill" className="block text-sm font-medium text-gray-700">Total Bill</label>
        <div className="relative mt-1">
          <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <Input
            type="number"
            id="totalBill"
            value={totalBill}
            onChange={(e) => setTotalBill(e.target.value)}
            className="pl-8"
          />
        </div>
      </div>

      {/* Friend list */}
      <div className="grid grid-cols-2 gap-4 mb-2">
        <h3 className="text-sm font-medium text-gray-700">Friend Name</h3>
        <h3 className="text-sm font-medium text-gray-700">Contribution</h3>
      </div>

      {friends.map((friend) => (
        <div key={friend.id} className="grid grid-cols-2 gap-4 items-center">
          <Input
            type="text"
            value={friend.name}
            onChange={(e) => handleFriendChange(friend.id, 'name', e.target.value)}
            onFocus={() => handleInputFocus(friend.id, 'name')}
            className="w-full"
          />
          <div className="relative flex-grow flex items-center">
            <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="number"
              value={friend.payment}
              onChange={(e) => handleFriendChange(friend.id, 'payment', e.target.value)}
              onFocus={() => handleInputFocus(friend.id, 'payment')}
              className="pl-8 w-full"
            />
            <Button 
              onClick={() => handleRemoveFriend(friend.id)} 
              variant="outline" 
              size="icon"
              className="ml-2 flex-shrink-0"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ))}

      <Button 
        onClick={handleAddFriend} 
        variant="outline" 
        className="w-full mt-4"
      >
        Add Friend
      </Button>

      <Button onClick={handleSplit} className="w-full mt-4">Split Bill</Button>
    </div>
  );
};

export default BillForm;
