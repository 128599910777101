import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import BillSplitter from './BillSplitter'; // Your bill splitter component
import './styles.css';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/split-bill" />} />
      <Route path="/split-bill" element={<BillSplitter />} />
    </Routes>
  </Router>
);

export default App;
