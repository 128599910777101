import React, { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "./components/ui/card";
import BillForm from './BillForm';
import SplitResult from './SplitResult';

const BillSplitter = () => {
  const [billName, setBillName] = useState('');
  const [totalBill, setTotalBill] = useState('');
  const [friends, setFriends] = useState([{ id: 1, name: 'Friend 1', payment: '0' }]);
  const [splitAmounts, setSplitAmounts] = useState([]);
  const [receipt, setReceipt] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState('');
  const fileInputRef = useRef(null);
  const [isSharedBill, setIsSharedBill] = useState(false);

  // Effect to handle shared bill details from URL params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sharedBillName = urlParams.get('billName');
    const sharedTotalBill = urlParams.get('totalBill');
    const sharedSplits = urlParams.get('splits');
    const sharedReceiptUrl = urlParams.get('receiptUrl'); // Get receiptUrl from query params
  
    if (sharedBillName && sharedTotalBill && sharedSplits) {
      setBillName(decodeURIComponent(sharedBillName));
      setTotalBill(decodeURIComponent(sharedTotalBill));
      if (sharedReceiptUrl) {
        setReceiptUrl(decodeURIComponent(sharedReceiptUrl)); // Set receiptUrl
      }
      try {
        const parsedSplits = JSON.parse(decodeURIComponent(sharedSplits));
        setSplitAmounts(parsedSplits);
        setIsSharedBill(true);
      } catch (error) {
        console.error('Error parsing shared splits:', error);
      }
    }
  }, []);

  const handleSplit = () => {
    const total = parseFloat(totalBill);
    if (isNaN(total) || total <= 0) {
      alert('Please enter a valid total bill amount');
      return;
    }

    const totalPayments = friends.reduce((sum, friend) => sum + (parseFloat(friend.payment) || 0), 0);
    const remainingBill = total - totalPayments;
    const numFriends = friends.length;

    if (remainingBill < 0) {
      alert('The total of down payments exceeds the bill amount');
      return;
    }

    const equalSplitAmount = remainingBill / numFriends;

    const newSplitAmounts = friends.map(friend => {
      const downPayment = parseFloat(friend.payment) || 0;
      const amountDue = equalSplitAmount - downPayment;
      return {
        name: friend.name,
        amountDue: amountDue.toFixed(2)
      };
    });

    setSplitAmounts(newSplitAmounts);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setReceipt(file);
      // Simulate upload to get URL
      const fakeUploadUrl = `https://fake-storage.com/${file.name}`;
      setReceiptUrl(fakeUploadUrl);
    }
  };

  const generateShareableLink = () => {
    const baseUrl = `${window.location.origin}${window.location.pathname}`;
    const queryParams = new URLSearchParams({
      billName: encodeURIComponent(billName),
      totalBill: encodeURIComponent(totalBill),
      splits: encodeURIComponent(JSON.stringify(splitAmounts)),
      receiptUrl: encodeURIComponent(receiptUrl) // Include receipt URL in the shareable link
    });
    return `${baseUrl}?${queryParams.toString()}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center py-6">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <Card className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 w-full max-w-md mx-auto">
          <CardHeader>
            <CardTitle className="text-2xl font-semibold mb-5">Bill Splitter</CardTitle>
          </CardHeader>
          <CardContent>
            {!isSharedBill ? (
              <BillForm
                billName={billName}
                setBillName={setBillName}
                totalBill={totalBill}
                setTotalBill={setTotalBill}
                friends={friends}
                setFriends={setFriends}
                receipt={receipt}
                setReceipt={setReceipt}
                fileInputRef={fileInputRef}
                handleFileUpload={handleFileUpload}
                handleSplit={handleSplit}
                setReceiptUrl={setReceiptUrl} // Pass setReceiptUrl to BillForm
              />
            ) : (
              <div className="mb-4">
                <h3 className="text-lg font-semibold">Shared Bill Details</h3>
                <p>Bill Name: {billName}</p>
                <p>Total Bill: ${totalBill}</p>
                {receiptUrl && (
                  <p>
                    Receipt: <a href={receiptUrl} target="_blank" rel="noopener noreferrer">View Receipt</a>
                  </p>
                )}
              </div>
            )}
            {splitAmounts.length > 0 && (
              <SplitResult
                billName={billName}
                totalBill={totalBill}
                splitAmounts={splitAmounts}
                isSharedBill={isSharedBill}
                generateShareableLink={generateShareableLink}
                receiptUrl={receiptUrl} // Pass receiptUrl to SplitResult
              />
            )}
          </CardContent>
        </Card>
      </div>
      <footer className="mt-8 py-4 text-center text-gray-500">
        © {new Date().getFullYear()} Carlos Guerrero. All rights reserved.
      </footer>
    </div>
  );
};

export default BillSplitter;
